<template>
  <div class="auth-wrapper auth-v2">
    <b-row class="auth-inner m-0">
      <!-- Brand logo-->
      <b-link class="brand-logo">
        <!-- <vuexy-logo /> -->
        <b-img
          fluid
          :src="logoUrl"
          alt="Muqaym"
          style="height: 50px; width: auto"
        />
        <h2 class="brand-text text-primary ml-1" />
      </b-link>
      <!-- /Brand logo-->

      <!-- Left Text-->
      <b-col
        lg="4"
        class="d-none d-lg-flex align-items-center p-5"
        :style="{
          backgroundImage: `url(' ${imgUrl}')`,
          backgroundSize: 'cover',
        }"
      >
        <div
          class="w-100 d-lg-flex align-items-center justify-content-center px-5"
        />
      </b-col>
      <!-- /Left Text-->

      <!-- Register-->
      <b-col
        lg="8"
        class="d-flex align-items-center auth-bg px-2 p-lg-5"
      >
        <b-col
          sm="8"
          md="6"
          lg="12"
          class="px-xl-2 mx-auto"
        >
          <b-card-title
            title-tag="h2"
            class="font-weight-bold mb-1"
          >
            المغامرة تبدأ هنا 🚀
          </b-card-title>
          <b-card-text class="mb-2">
            التكلفة لا تقارن بما تقدمه منصة مقيّم من مزايا، ابدأ بالتجربة ولن
            تندم!
          </b-card-text>
          <!-- pricing plan cards -->
          <b-row class="pricing-card">
            <b-col
              offset-sm-2
              sm="10"
              md="12"
              offset-lg="2"
              lg="10"
              class="mx-auto"
            >
              <b-row>
                <b-col
                  v-for="membership in memberships"
                  :key="membership.title"
                  md="6"
                >
                  <b-card
                    class="popular"
                    align="center"
                  >
                    <div class="pricing-badge text-right">
                      <b-badge
                        v-show="membership.recommended"
                        variant="light-primary"
                        pill
                      >
                        رائج
                      </b-badge>
                    </div>
                    <!-- img -->
                    <b-img
                      v-if="membership.img"
                      :src="membership.img"
                      class="mb-1"
                      alt="svg img"
                    />
                    <!--/ img -->
                    <h3>{{ membership.title }}</h3>
                    <b-card-text>{{ membership.subtitle }}</b-card-text>

                    <!-- buttons -->
                    <b-button
                      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                      block
                      class="mt-2"
                      :to="membership.url"
                      variant="primary"
                    >
                      {{ membership.btnLabel }}
                    </b-button>
                  </b-card>
                </b-col>
              </b-row>
            </b-col>
          </b-row>
          <!--/ pricing plan cards -->
          <p class="text-center mt-2">
            <span>هل لديك حساب؟</span>
            <b-link to="login">
              <span>&nbsp;تسجيل الدخول</span>
            </b-link>
          </p>
        </b-col>
      </b-col>
      <!-- /Register-->
    </b-row>
  </div>
</template>

<script>
import {
  BFormCheckbox,
  BRow,
  BCol,
  BCard,
  BImg,
  BLink,
  BCardText,
  BCardTitle,
  BListGroup,
  BListGroupItem,
  BButton,
  BBadge,
} from 'bootstrap-vue';
import AppCollapse from '@core/components/app-collapse/AppCollapse.vue';
import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem.vue';
import store from '@/store/index';
import Ripple from 'vue-ripple-directive';
/* eslint-disable global-require */
export default {
  components: {
    BFormCheckbox,
    BButton,
    BCardText,
    BCardTitle,
    BListGroup,
    BListGroupItem,
    BRow,
    BCol,
    BCard,
    BBadge,
    BLink,
    BImg,
    AppCollapseItem,
    AppCollapse,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      sideImg: require('@/assets/images/stock/900x600/19.jpg'),
      memberships: [
        {
          title: 'شركة',
          img: require('@/assets/images/illustration/Pot3.svg'),
          subtitle: 'للشّركات المعتمدة من قبل هيئة المقييمين',
          url: 'register/company',
          recommended: true,
          btnLabel: 'التسجيل كشركة',
        },
        {
          title: 'عضو مقييم',
          img: require('@/assets/images/illustration/Pot2.svg'),
          subtitle: 'للمقيمين المعتمدين المستقلّين',
          url: 'register/member',
          recommended: false,
          btnLabel: 'التسجيل كعضو',
        },
      ],
    };
  },
  computed: {
    logoUrl() {
      return require('@/assets/images/logo/muqaym-logo.png');
    },
    imgUrl() {
      if (store.state.appConfig.layout.skin === 'dark') {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.sideImg = require('@/assets/images/pages/login-v2-dark.svg');
        return this.sideImg;
      }
      return this.sideImg;
    },
  },
};
/* eslint-disable global-require */
</script>

<style lang="scss">
@import "@core/scss/vue/pages/page-pricing.scss";
@import "@core/scss/vue/pages/page-auth.scss";
</style>
